@use '~@gr4vy/poutine-tokens/scss/index.scss' as *;

.bar {
  width: 100%;
  height: calc($size-8 / 2);
  background-color: $color-gray-30;
  border-radius: $radius-pill;
  overflow: hidden;
}

.fill {
  height: 100%;
  background-color: $color-blue-60;
}
