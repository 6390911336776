@use '~@gr4vy/poutine-tokens/scss/index.scss' as *;

$size: $font-size-sm;
$color: var(--status-dot-color, $color-gray-70);

.dot {
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: $size-8;

  &:before {
    content: '';
    width: $size;
    height: $size;
    background-color: $color;
    border: $border-width-md solid transparent;
    border-radius: $size;
  }
}
