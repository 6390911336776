@use '~@gr4vy/poutine-tokens/scss/index.scss' as *;

.textRight {
  text-align: right;
}

.filter {
  margin-left: calc($space-24 / -2);

  &.amount {
    margin: 0 $space-32 * -1;
  }
}

.amountWithNoFilter {
  margin-right: $space-24;
  text-align: right;
}

.timeLineWrapper {
  padding: $space-16 $space-16 $space-4 $space-16;
}

.infoIcon {
  align-self: start;
}
