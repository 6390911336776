@use '~@gr4vy/poutine-tokens/scss/index.scss' as *;

.header {
  border-top: 1px solid $color-gray-40;
}

.clickableRow {
  position: relative;
  cursor: pointer;

  &:hover {
    background-color: $color-gray-10;
  }

  &:active {
    background-color: $color-gray-20;
  }

  &[data-state='selected'] {
    background-color: $color-blue-10;
  }

  &[data-state='disabled'] {
    td {
      opacity: 0.5;
    }
  }

  &:focus-visible {
    outline-offset: -2px;
    outline: 2px solid $color-blue-20;
  }
}

td,
th {
  &.cell {
    padding-left: $space-8;
    padding-right: $space-8;
  }
}

@keyframes spinning {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  animation: 1.2s steps(8) spinning infinite;
}

.error {
  padding: 0;

  > div {
    padding-left: $space-32;
    padding-right: $space-32;
  }
}
